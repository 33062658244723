<template>
  <div id="app" style="height: 100vh; width: 100vw; background: #FFFFFF">
    <!--异常页面-->
    <div style="height: 100vh; width: 100vw;" v-if="state === 'ABNORMAL'">
      <div><img src="../../../src/assets/images/common/icon_state_warning.png" alt=""
                style="width: 24vw; position: relative; left: 38vw; top: 8vh;"/></div>
      <div style=" text-align: center; position: relative; top: 11vh; font-size: 16px; font-weight: normal">{{ info.title }}</div>
    </div>
    <!--查询中-->
    <div style="height: 100vh; width: 100vw;" v-if="state === 'QUERYING'">
      <van-loading color="#34AC40" size="24vw" vertical style="top: 8vh;"></van-loading>
      <div style=" text-align: center; position: relative; top: 11vh; font-size: 16px; font-weight: normal">{{ info.title }}
      </div>
    </div>
    <!--成功-->
    <div style="height: 100vh; width: 100vw;" v-if="state === 'SUCCESS'">
      <div><img src="../../../src/assets/images/common/icon_state_success.png" alt=""
                style="width: 24vw; position: relative; left: 38vw; top: 8vh;"/></div>
      <div style=" text-align: center; position: relative; top: 11vh; font-size: 16px; font-weight: normal">{{ info.title }}</div>
      <div style=" text-align: center; position: relative; top: 12vh; font-size: 18px; font-weight: bold">{{ info.content }}</div>
      <div style=" text-align: center; position: relative; top: 13vh; font-size: 16px; font-weight: normal">{{ info.reason }}
      </div>

      <div class="bank_btn " style="position: relative; top: 20vh;">
        <van-button color="#1C8C69" @click="routeToRecharge" style="font-size: 16px">确认</van-button>
      </div>

    </div>
    <!--失败-->
    <div style="height: 100vh; width: 100vw;" v-if="state === 'FAIL'">
      <div><img src="../../../src/assets/images/common/icon_state_failure.png" alt=""
                style="width: 24vw; position: relative; left: 38vw; top: 8vh;"/></div>
      <div style=" text-align: center; position: relative; top: 11vh; font-size: 16px; font-weight: normal">{{ info.title }}</div>
      <div style=" text-align: center; position: relative; top: 12vh; font-size: 18px; font-weight: bold">{{ info.content }}</div>
      <div style=" text-align: center; position: relative; top: 13vh; font-size: 16px; font-weight: normal">{{ info.reason }}
      </div>

      <div class="bank_btn " style="position: relative; top: 20vh;">
        <van-button color="#D1D1D1" @click="routeToRecharge" style="font-size: 16px" v-show="!showQueryRetry">返回</van-button>
        <van-button color="#D1D1D1" @click="retryQueryDealResult" style="font-size: 16px" v-show="showQueryRetry">重试</van-button>
      </div>

    </div>

  </div>
</template>

<script>
import {post} from "../../utils/http";
import {Notify} from "vant";

export default {
  name: "DealResult",
  data() {
    return {
      depositId: "",
      state: "",
      showQueryRetry: false,
      data: {},
      info: {
        title: "",
        content: "",
        reason: ""
      }
    }
  },
  mounted() {
    // url 的参数由银联返回
    let srcReverse = this.$route.query.srcReverse;
    if (!srcReverse) {
      this.setAbnormal();
      return;
    }

    this.depositId = JSON.parse(srcReverse).depositId;
    if (!this.depositId || this.depositId === '') {
      this.setAbnormal()
      return;
    }

    // 正常显示
    this.queryDealResult();

    // 设置20秒超时
    setInterval(() => {
      if (this.state === 'QUERYING') {
        this.state = 'FAIL';
        this.showQueryRetry = true;
      }
    }, 20000)
  },
  methods: {
    /*
    "data": {
        "totalAmount": "0.01",
        "errCode": "SUCCESS",
        "typeName": "充值",
        "type": "RECHARGE",
        "errMessage": "成功",
        "statusMessage": "交易创建，等待买家付款。",
        "status": "WAIT_BUYER_PAY"
    }
     */
    queryDealResult() {
      post('api/pay/deposit/result/query', {depositId: this.depositId})
          .then(result => {
            this.data = result.data;

            if (result.data.type === 'RECHARGE') {
              if (result.data.status === 'TRADE_SUCCESS') {
                this.state = 'SUCCESS';

                this.info = {
                  title: "充值成功",
                  content: result.data.totalAmount,
                  reason: ""
                }

              } else {
                this.state = 'FAIL';

                this.info = {
                  title: "充值失败",
                  content: result.data.totalAmount,
                  reason: ""
                }
              }

            } else if (result.data.type === 'WITHDRAW') {
              if (result.data.status === 'SUCCESS') {
                this.state = 'SUCCESS';

                this.info = {
                  title: "提现成功",
                  content: result.data.totalAmount,
                  reason: ""
                }

              } else {
                this.state = 'FAIL';

                this.info = {
                  title: "提现失败",
                  content: result.data.totalAmount,
                  reason: ""
                }
              }

            } else {
              this.state = 'FAIL';

              this.info = {
                title: "交易结果查询失败",
                content: "",
                reason: ""
              }
              return;
            }

          })
          .catch(error => {
            Notify({type: 'warning', message: error.message});
            this.state = 'FAIL';

            this.info = {
              title: "交易结果查询失败",
              content: error.message,
              reason: ""
            }

          })
    },
    routeToRecharge() {
      this.$router.replace({path: "/recharge"})
    },
    retryQueryDealResult() {
      this.state = 'QUERYING';
      this.info = {
        title: "交易结果查询中，请稍后",
        content: "",
        reason: ""
      }
      this.showQueryRetry = false;
      this.queryDealResult();
    },
    setAbnormal() {
      this.state = "ABNORMAL";
      this.info = {
        title: "交易异常",
        content: "",
        reason: ""
      }
      // 3秒后跳转index
      setInterval(() => {
        this.$router.push({path: '/index'})
      }, 3000)
    }
  }

}
</script>

<style scoped>

</style>